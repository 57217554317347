<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="primary"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
          :class="{'shake' : shaking}"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="text-h3 font-weight-bold mb-0">
                Login
              </h1>

              <!--               <v-btn
                v-for="(social, i) in socials"
                :key="i"
                :href="social.href"
                class="ma-1"
                icon
                rel="noopener"
                target="_blank"
              >
                <v-icon
                  v-text="social.icon"
                />
              </v-btn> -->
            </div>
          </template>

          <v-card-text class="text-center">
            <!--             <div class="text-center grey--text body-1 font-weight-light">
              Or Be Classical
            </div> -->

            <!--             <v-text-field
              color="secondary"
              label="First Name..."
              prepend-icon="mdi-face"
              class="mt-10"
            /> -->

            <v-text-field
              v-model="email"
              color="secondary"
              label="Email"
              prepend-icon="mdi-email"
            />

            <v-text-field
              v-model="password"
              class="mb-8"
              color="secondary"
              label="Password"
              prepend-icon="mdi-lock-outline"
              :append-icon="showPassword ?'mdi-eye':'mdi-eye-off'"
              @click:append="showPassword =! showPassword"
              :type="showPassword ?'text': 'password'"

            />

            <pages-btn
              large
              color=""
              depressed
              class="v-btn--text primary--text"
              @click.stop="signinUser()"
              id="login-button"
            >
              Log In
            </pages-btn>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PagesLogin",

  components: {
    PagesBtn: () => import("./components/Btn"),
  },

  data: () => ({
    errorM: '',
    shaking: false,
    showPassword: false,
    email: "",
    password: "",
    socials: [
      {
        href: "#",
        icon: "mdi-facebook-box",
      },
      {
        href: "#",
        icon: "mdi-twitter",
      },
      {
        href: "#",
        icon: "mdi-github-box",
      },
    ],
  }),
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$router.push("/");
        }
      },
    },
    shaking: {
      immediate: false,
      handler(value) {
        if (value) {
          setTimeout( () => {this.shaking = false}, 500);
        }
      }
    }
  },
  methods: {
    signinUser() {
      this.$store.dispatch("signinUser", {
        email: this.email,
        password: this.password,
      }).then( response => {
       
      }, error => {
        this.errorM = error.message
        this.shaking = true
      });
    },
  },
  created() {
      const footerDiv = document.querySelector('.v-footer');
      const loginDiv = document.querySelector('#login');

      footerDiv.addEventListener('scroll', () => {
              var loginPos = loginDiv.getBoundingClientRect();
              var footerPos = footerDiv.getBoundingClientRect();
              if (footerPos.top <= loginPos.bottom) {
                footerDiv.style.display="none";
              }
      });

      const loginButton = document.getElementById("app")
      loginButton.addEventListener("keypress", (event) => {
        if (event.key === "Enter") {
          event.preventDefault()
          this.signinUser()
        }
      });

  }
};
</script>

<style scoped>
    .shake {
			-webkit-animation: kf_shake 0.4s 1 linear;
			-moz-animation: kf_shake 0.4s 1 linear;
			-o-animation: kf_shake 0.4s 1 linear;
		}
		@-webkit-keyframes kf_shake {
			0% { -webkit-transform: translate(30px); }
			20% { -webkit-transform: translate(-30px); }
			40% { -webkit-transform: translate(15px); }
			60% { -webkit-transform: translate(-15px); }
			80% { -webkit-transform: translate(8px); }
			100% { -webkit-transform: translate(0px); }
		}
		@-moz-keyframes kf_shake {
			0% { -moz-transform: translate(30px); }
			20% { -moz-transform: translate(-30px); }
			40% { -moz-transform: translate(15px); }
			60% { -moz-transform: translate(-15px); }
			80% { -moz-transform: translate(8px); }
			100% { -moz-transform: translate(0px); }
		}
		@-o-keyframes kf_shake {
			0% { -o-transform: translate(30px); }
			20% { -o-transform: translate(-30px); }
			40% { -o-transform: translate(15px); }
			60% { -o-transform: translate(-15px); }
			80% { -o-transform: translate(8px); }
			100% { -o-origin-transform: translate(0px); }
		}
</style>